<template>
  <div class="w-full">
    <l-map style="height: 400px;" :zoom.sync="zoomMap" :center.sync="centerMap">
      <l-tile-layer :options="{id: 'mapbox/streets-v11'}" url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmFqYXJmdWFkIiwiYSI6ImNraW9jdzBjMTFiZ28zMG13bW55aXYwbncifQ.kmgRy9O_gZ2RUlbjtWxb-Q"></l-tile-layer>
      <div v-if="is_draggable">
        <l-marker v-for="(val, i) in markerLatLng" :key="'driver-' + i" @update:lat-lng="markerMoved" :icon="iconMarker" :lat-lng="markerLatLng[i]" @click="focusMarker(i)" draggable>
          <div v-if="with_tooltip">
            <l-tooltip :options="{ permanent: true, interactive: true }">{{val.contact_name}}</l-tooltip>
          </div>
        </l-marker>
      </div>
      <div v-else>
        <l-marker v-for="(val, i) in markerLatLng" :key="'driver-' + i" :icon="iconMarker" :lat-lng="markerLatLng[i]" @click="focusMarker(i)">
          <div v-if="with_tooltip">
            <l-tooltip :options="{ permanent: true, interactive: true }"><b>{{val.code}}</b><br/>{{val.contact_name}}</l-tooltip>
          </div>
        </l-marker>
        <l-marker v-for="(val, i) in additionalLatLng" :key="'vehicle-' + i" :icon="iconMarker" :lat-lng="additionalLatLng[i]" @click="focusAdditionalMarker(i)">
          <div v-if="with_tooltip">
            <l-tooltip :options="{ permanent: true, interactive: true }">
                {{val.name}}
                <span v-if='val.is_active'>
                    <vs-divider></vs-divider>
                    <span v-html='val.description'></span>
                </span>
            </l-tooltip>
          </div>
        </l-marker>
      </div>
      <!-- <v-geosearch :options="geosearchOptions" ></v-geosearch> -->
    </l-map>
    <vs-alert v-if="is_draggable" class="mt-2" icon-pack="feather" icon="icon-info" active="true"><small>Move the <b style="color: red;">Red Marker</b> to change location</small></vs-alert>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import L from 'leaflet';
import {LMap, LTileLayer, LMarker, LIcon, LTooltip} from 'vue2-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import VGeosearch from 'vue2-leaflet-geosearch';

const geoProvider = new OpenStreetMapProvider({
  params: {
    email : "support@syalog.com",
    "accept-language" : 'en'
  }
})

export default {
  props:['value', 'markerLatLng', 'additionalLatLng', 'centerLatLng', 'is_draggable', 'zoom', 'with_tooltip'],
  components:{
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
    VGeosearch
  },
  data(){
    return {
      centerMap: null,
      iconMarker: L.icon({
        iconUrl: require('@/assets/images/ico/marker-red.svg'),
        iconSize: [40,40]
      }),
      geosearchOptions: {
        provider: geoProvider,
        autoClose: true,
        showMarker: false
      },
    }
  },
  watch:{
    value(val){
      this.markerLatLng = val
    }
  },
  methods:{
    markerMoved(e){
      this.centerMap = [e.lat, e.lng]
      this.$emit('input', e)
    },
    focusMarker(index) {
      this.centerMap = [this.markerLatLng[index].lat, this.markerLatLng[index].lng]
    },
    focusAdditionalMarker(index) {
      this.centerMap = [this.additionalLatLng[index].lat, this.additionalLatLng[index].lng]
      this.additionalLatLng = this.additionalLatLng.map((e) => {
        e.is_active = false
        return e
      }) 
      this.additionalLatLng[index].is_active = true
    }
  },
  created() {
    this.zoomMap = this.zoom
    this.centerMap = this.centerLatLng
  }
}
</script>

<style>

</style>